import request from '@/utils/request'
// 获取直播
export function getInfo(params) {
  return request({
    url: '/portal/live/get-info',
    method: 'GET',
    params
  })
}

// 点赞
export function getLikes(data) {
  return request({
    url: '/portal/live/likes',
    method: 'POST',
    data
  })
}

//  获取在线人数
export function getOnlinePe(params) {
  return request({
    url: '/portal/live/get-on-line-number',
    method: 'GET',
    params
  })
}

//同问
export function sameAsk(data) {
  return request({
    url: '/portal/live-comment/same-ask',
    method: 'POST',
    data
  })
}

//修改问答（回复不允许修改）
export function editReply(data) {
  return request({
    url: '/portal/live-comment/edit',
    method: 'POST',
    data
  })
}

//删除问答/回复
export function delReply(data) {
  return request({
    url: '/portal/live-comment/delete',
    method: 'POST',
    data
  })
}

//新增问答
export function addReply(data) {
  return request({
    url: '/portal/live-comment/add',
    method: 'POST',
    data
  })
}

//直播问答列表
export function getQuesList(params) {
  return request({
    url: '/portal/live-comment/get-list',
    method: 'GET',
    params
  })
}

//直播问答回复列表（树形结构）
export function getReplyList(params) {
  return request({
    url: '/portal/live-comment/get-reply-list',
    method: 'GET',
    params
  })
}

//直播房公告列表
export function noticeList(params) {
  return request({
    url: '/portal/live-room-notice/get-list',
    method: 'GET',
    params
  })
}

//获取用户在直播房的观众信息
export function getLiveInfo(params) {
  return request({
    url: '/portal/live-viewer/get-info',
    method: 'GET',
    params
  })
}

//单手机用户登录/注册
export function relogin(data) {
  return request({
    url: '/common/auth/relogin',
    method: 'POST',
    data
  })
}


//获取评论详情
export function getLiveDetailInfo(params) {
  return request({
    url: '/portal/live-comment/get-info',
    method: 'GET',
    params
  })
}